<template>

  <!-- Start Content-->
  <div>
    <div class="alert alert-warning" v-if="downloadAlert">{{ downloadAlert }}</div>

    <div class="responsive-table-plugin btn-toolbar align-items-center justify-content-between">

      <div class="form-inline md-form form-sm toolbar-search">
        <input class="form-control mr-1 w-80" type="search" placeholder="Search" aria-label="Search"
          v-model="listStrage.query" @keyup.enter="search">
        <select class="form-control ml-2" :placeholder="lan.pay_user" v-model="utype">
          <option value="">
            <div class="lang" v-html="lan.pay_user"></div>
          </option>
          <option value="1">
            <div class="lang" v-html="lan.yes"></div>
          </option>
          <option value="0">
            <div class="lang" v-html="lan.no"></div>
          </option>
        </select>
        <select class="form-control ml-2" :placeholder="lan.all_user" v-model="listtype">
          <option value="">
            <div class="lang" v-html="lan.all_user"></div>
          </option>
          <option value="admin">
            <div class="lang" v-html="lan.parent"></div>
          </option>
        </select>
        <select class="form-control ml-2" :placeholder="lan.service" v-model="service">
          <option value="">
            <div class="lang" v-html="lan.service"></div>
          </option>
          <option value="service1">
            <div class="lang" v-html="lan.service1"></div>
          </option>
          <option value="service2">
            <div class="lang" v-html="lan.service2"></div>
          </option>
          <option value="service3">
            <div class="lang" v-html="lan.service3"></div>
          </option>
        </select>
        <select class="form-control ml-2" v-model="status">
          <option value="">
            <div class="lang" v-html="lan.status"></div>
          </option>
          <option value="1">
            <div class="lang" v-html="lan.nomarl"></div>
          </option>
          <option value="2">
            <div class="lang" v-html="lan.once_stop"></div>
          </option>
          <option value="0">
            <div class="lang" v-html="lan.stop"></div>
          </option>
        </select>
        <select class="form-control ml-2" v-model="step">
          <option value="">
            <div class="lang" v-html="lan.step"></div>
          </option>
          <option value="1">
            <div class="lang" v-html="lan.step1"></div>
          </option>
          <option value="2">
            <div class="lang" v-html="lan.step2"></div>
          </option>
          <option value="4">
            <div class="lang" v-html="lan.step4"></div>
          </option>
          <option value="5">
            <div class="lang" v-html="lan.step5"></div>
          </option>
          <option value="6">
            <div class="lang" v-html="lan.step6"></div>
          </option>
          <option value="6.1">
            <div class="lang" v-html="lan['step6.1']"></div>
          </option>
          <option value="7">
            <div class="lang" v-html="lan.step7"></div>
          </option>
        </select>
        <select class="form-control ml-2" :placeholder="lan.member_group" v-model="group">
          <option value="">
            <div class="lang" v-html="lan.member_group"></div>
          </option>
          <option value="1">
            <div class="lang" v-html="lan.yes"></div>
          </option>
          <option value="0">
            <div class="lang" v-html="lan.no"></div>
          </option>
        </select>
        <button type="button" class="btn btn-white ml-2" @click="search">
          <i class="fas fa-search" aria-hidden="true"></i>
        </button>
        <button type="button" class="btn btn-white ml-2" @click="clearn">
          <div class="lang" v-html="lan.mng_btn_clear"></div>
        </button>
      </div>

      <div class="form-inline toolbar-download">
        <button type="button" class="btn btn-primary" v-if="viewParams.name.indexOf('corp') === 0"
          @click="listDownload">
          <div class="lang" v-html="lan.download"></div>
        </button>
      </div>

      <div class="form-inline toolbar-display">

        <div class="mr-2 toolbar-display-count">
          <span class="mr-1">
            <div class="lang" v-html="lan.show_limit"></div>
          </span>
          <div>
            <select class="form-control mt-0" v-model.lazy="listStrage.limit" @change="search">
              <option>10</option>
              <option>20</option>
              <option>50</option>
              <option>100</option>
              <option>200</option>
              <option>500</option>
            </select>
          </div>
        </div>



      </div>
    </div>


    <div class="d-flex justify-content-center p-5" v-if="isLoading">
      <div class="spinner-border m-12" role="status">
        <span class="sr-only">Loading...</span>
      </div>
    </div>
    <div v-else-if="!jsonErr && !checkKeys.length">
      <!-- 表示項目無し -->
      <code>
        表示項目が選択されていません。
      </code>
    </div>
    <div class="responsive-table-plugin" v-else-if="!jsonErr">

      <div class="table-rep-plugin">
        <div class="table-responsive">
          <table class="table table-striped focus-on">
            <thead>
              <tr>
                <th class="action"></th>
                <th v-for="tableHeader in tableHeaders" :key="tableHeader" :class="maxs.includes(tableHeader) ?'max':''"
                  v-show="checkKeys.includes(tableHeader) && shows.includes(tableHeader)"> {{
                  haslans.indexOf(tableHeader)>-1 ? lan[tableHeader]:tableHeader }}</th>

              </tr>
            </thead>
            <tbody v-if="jsonObj.count && checkKeys.length">
              <tr v-for="(obj, idNum) in listData" :key="idNum"
                :class="{ 'iserror' : obj['dueToPaymentDay']<ndate && obj['paymentConfirmDay']>0  }"
                @click="toggleFocus(idNum)" @child="toggleFocus(idNum)">
                <td class="action"> <button type="button" class="btn sendmail btn-white ml-2 btn-upd"
                    @click="upd(obj['メールアドレス'])">
                    <div v-if="obj['有償']>0" class="lang" v-html="lan.updateFeeDay"></div>
                    <div v-if="obj['有償']==0" class="lang" v-html="lan.time_limit"></div>
                  </button> <button class="btn btn-danger ml-2" @click="saveStop(obj['メールアドレス'], idNum)">
                    <div class="lang" v-html="lan.stop"></div>
                  </button>
                  <button class="btn btn-white ml-2 refuse_doing"
                    v-if="obj['negotiation_date'] != null && obj['negotiation_date'] !=''">
                    <div class="lang" v-html="lan.refuse_doing"></div><br>{{ obj['negotiation_date'] }}
                  </button>
                </td>
                <td v-for="(value, keyName) in obj" :key="keyName" :class="maxs.includes(keyName) ?'max':''"
                  v-show="shows.includes(keyName)">
                  <div class="table-data-container">

                    <span v-if="yesno_field.indexOf(keyName)>-1 "> {{ (value==1? lan.yes:lan.no) }}</span>

                    <span v-else-if="keyName=='dueToPaymentDay' "> <input type="text" class="tinput"
                        v-model="dateArr[idNum]"> <button class="btn btn-white ml-2 btn-upd"
                        @click="saveConfirm(obj['メールアドレス'],idNum)">
                        <div class="lang" v-html="lan.save"></div>
                      </button> </span>
                    <span v-else-if="apply_field.indexOf(keyName)>-1 "> {{ (value==1? lan.available:lan.unavailable)
                      }}</span>
                    <span v-else-if="keyName.indexOf('状態')>-1 "> {{ (value!=2 ? value!=1 ?
                      lan.stop:lan.nomarl:lan.once_stop) }}</span>
                    <span v-else-if="keyName.indexOf('メールアドレス') > -1"> {{ value }}<span class="remark-box"> <textarea
                          class="tarea" v-model="obj['remark']" @keyup="obj.is_change = true"></textarea> <button
                          class="btn btn-white ml-2 btn-upd" @click="saveRefuse(obj, idNum, obj['remark'])"
                          v-if="obj.is_change">
                          <div class="lang" v-html="lan.refuse"></div>
                        </button> </span></span>
                    <span v-else> {{ value }}</span>
                  </div>


                </td>

              </tr>
            </tbody>
          </table>
          <div class="p-2">
            <code v-if="!jsonObj.total_count">表示データがありません。</code>
          </div>
        </div> <!-- end .table-responsive -->
      </div> <!-- end .table-rep-plugin-->

      <div class="d-flex justify-content-end mt-1" v-if="jsonObj.total_count">
        <span>{{ (listStrage.paged - 1) * listStrage.limit + 1 }}</span>
        &nbsp;~&nbsp;
        <span>{{ (listStrage.paged - 1) * listStrage.limit + jsonObj.count }}</span>
        &nbsp;/&nbsp;
        <span>{{ jsonObj.total_count }}</span>
      </div>
      <div class="d-flex justify-content-end mt-1" v-else>
        <span>0</span>
        &nbsp;~&nbsp;
        <span>0</span>
        &nbsp;/&nbsp;
        <span>0</span>
      </div>

      <ul class="pagination pagination-split justify-content-end flex-wrap footable-pagination mt-1"
        v-if="jsonObj.total_count">
        <li class="footable-page-arrow mb-1"><a href="#" @click.prevent.stop="pagenateKey('first')">«</a></li>
        <li class="footable-page-arrow mb-1"><a href="#" @click.prevent.stop="pagenateKey('prev')">‹</a></li>
        <li class="footable-page mb-1" :class="{'active': num === listStrage.paged, 'hide': pageHide(num) }"
          v-for="num in maxPage" :key="num"><a href="#" @click.prevent.stop="pagenateNum(num)">{{ num }}</a></li>
        <li class="footable-page-arrow mb-1"><a href="#" @click.prevent.stop="pagenateKey('next')">›</a></li>
        <li class="footable-page-arrow mb-1"><a href="#" @click.prevent.stop="pagenateKey('last')">»</a></li>
      </ul>
    </div> <!-- end .responsive-table-plugin-->
    <div v-else>
      <code>
      データ取得に失敗しました。
      </code>
    </div>


  </div> <!-- container -->

</template>

<script>

import axios from 'axios'
import { inject } from 'vue';
import {getNdate} from '../components/common'
export default {
  name: 'ListTableManager',
  props: {
    viewParams: {
      name: {
        type: String,
        default: ''
      },
      apiUrl: {
        type: String,
        default: '',
      },
      dataType: {
        type: String,
        default: 1,
      },
      articleView: String,
      articleParamId: String
    },
  },
  components: {
  },
  data() {
    return {
      offset: 0,
      isPopupUser: 0,
      listStrage: {
        query: '',
        limit: 50,
        listtype:'user',
        paged: 1,
      },
      maxPage: 0,
      checkKeys: [],
      jsonObj: {
        offset: 0,
        count: 0,
        total_count: 0,
        data: [Object]
      },
      tableHeaders: [],
      listData: [Object],
      isFocus: -1,
      ddMenu: false,
      jsonErr: false,
      isLoading: true,
      downloadAlert: '',
      cur_index:0,
      cur_obj:{tomail:'',subject:'',body:''},
      yesno_field:['有償','親'],
      haslans:'paymentConfirmDay,dueToPaymentDay,isPaid',
      apply_field:['リテールマーケット','オフィスマーケット','インダスマーケット'],
      shows:['メールアドレス','電話番号','会社名','paymentConfirmDay','dueToPaymentDay','担当者・姓','担当者・名','担当者・部署名','担当者・役職','契約期限 開始日','契約期限 終了日','リテールマーケット','オフィスマーケット','インダスマーケット','利用ユーザー数'],
      isPopup:0,
      exceptionKey: ['ユーザーID'], 
      maxs:['メールアドレス','会社名','会社名かな','部署名','役職','dueToPaymentDay','remark'],
      pidData:[],
      lan:inject('global').creis_language_data,
      cur_lan:localStorage.getItem('cur_lan') !="en" ? 'jp':"en",
      cka:false,
      listtype:'',
      service:'',
      utype:'',
      group:0,
      step:'',
      status:'',
      dateArr:[],
      ndate:getNdate(),
      cid:0,
      cur_idnum:0,
      cur_remark:'',
    }
  },
  mounted() {

    this.cur_lan=localStorage.getItem('cur_lan') !="en" ? "jp":"en";
    let creis_language_data=JSON.parse(localStorage.getItem('creis_language_data'));
    this.lan=creis_language_data['menu_data_'+this.cur_lan]
   
    if( localStorage.getItem(this.viewParams.name + '_limit') ) {
      this.listStrage.limit = localStorage.getItem(this.viewParams.name + '_limit')
    }
    if( sessionStorage.getItem(this.viewParams.name + '_query') ) {
      this.listStrage.query = sessionStorage.getItem(this.viewParams.name + '_query')
    }
    if( sessionStorage.getItem(this.viewParams.name + '_paged') ) {
      this.listStrage.paged = Number(sessionStorage.getItem(this.viewParams.name + '_paged'))
    }
    
    this.getList()
    
  },
  methods: {
  getList(){
    axios
        .get(this.viewParams.apiUrl, {
      params: {
        offset: (this.listStrage.paged - 1) * this.listStrage.limit, // 開始位置
        page: this.listStrage.paged - 1,
        limit: this.listStrage.limit, //表示件数
        listtype: this.listtype,
        service: this.service,
        utype: this.utype,
        status: this.status,
        step: this.step,
        q: this.listStrage.query //キーワード
      }
    })
      .then(response => {
        this.jsonObj = response.data;
        this.tableHeaders = response.data.data.length > 0 ? Object.keys(response.data.data[0]) : [];
        this.listData = response.data.data;
        for (var d in this.listData) {
          this.listData[d].ckd = 0;
          this.listData[d].is_change = false;
          this.dateArr[d] = this.listData[d].dueToPaymentDay;
        }
        this.pidData = this.jsonObj.pidData;
        this.maxPage = Math.ceil(this.jsonObj.total_count / this.listStrage.limit);
        this.checkKeys = Object.keys(this.listData[0]);
        sessionStorage.setItem(this.viewParams.name + '_paged', this.listStrage.paged);
        sessionStorage.setItem(this.viewParams.name + '_query', this.listStrage.query);
        sessionStorage.setItem(this.viewParams.name + '_paged', this.listStrage.paged);
        this.isLoading = false
      }
      )
      .catch(error => {
        console.log(error),
          this.jsonErr = true,
          this.isLoading = false
      }
      );
  },
    toggleDDMenu: function() {
      this.ddMenu = !this.ddMenu
    },
    allCheck: function() {
      this.checkKeys = this.tableHeaders
    },
    ckall: function(){
      if(this.cka==false){
        this.cka=true;
        for(var d in this.listData ){
          this.listData[d].ckd=true;
        }
      }else{
        this.cka=false;
        for(var dd in this.listData ){
          this.listData[dd].ckd=false;
        }
      }
    },
    checkone:function(index){
      this.listData[index].ckd=!this.listData[index].ckd;
      if(!this.listData[index].ckd){
        this.cka=false;
      }
      console.log(this.listData)
    },
    allUncheck: function() {
      this.checkKeys = []
    },
    toggleFocus: function(idNum) {
      this.isFocus = idNum;
    },
    saveConfirm:function(cid,idNum){
      
   
     
      if(confirm(this.lan.alter_confirm_save)){
        this.isLoading = true;
        axios
        .post('creisFeeManagerUpdate',{cid:cid,update_type:'update_date',dueToPaymentDay:this.dateArr[idNum]} )
            .then((response) => {
            
            if(response.data.status){
              //alert(this.lan.success)
              console.log('save success'); 
              //window.location.reload();//reload the page
              }
            
            this.isLoading = false
            
            })
            .catch(error => {
        
            this.isLoading = false
            console.log(error)
            });
        
      }
    },
    saveRefuse: function (obj, idNum) {
    
        this.cur_idnum=idNum;
        this.cur_remark = obj.remark;
        this.cid = obj['メールアドレス'];
      if (confirm(this.lan.alter_confirm_save)) {
        this.isLoading = true;
        axios
          .post('creisFeeManagerUpdate', { cid: this.cid, remark: this.cur_remark, update_type: 'negotiation_date', dueToPaymentDay: this.dateArr[idNum] })
          .then((response) => {

            if (response.data.status) {
              //alert(this.lan.success)
              console.log('save success');
              //window.location.reload();//reload the page
            }

            this.isLoading = false

          })
          .catch(error => {

            this.isLoading = false
            console.log(error)
          });

      }
    },
    saveStop: function (cid, idNum) {
      this.cur_idnum = idNum;
      this.cur_remark = this.listData[idNum].remark
      this.cid = cid;
      if (confirm(this.lan.alter_confirm_save)) {
        this.isLoading = true;
        axios
          .post('creisFeeManagerUpdate', { cid: cid, update_type: 'stop_date', dueToPaymentDay: this.dateArr[idNum] })
          .then((response) => {

            if (response.data.status) {
              //alert(this.lan.success)
              console.log('save success');
              //window.location.reload();//reload the page
            }

            this.isLoading = false
            this.search();

          })
          .catch(error => {

            this.isLoading = false
            console.log(error)
          });

      }
    },
    upd: function(cid) {

      if(confirm(this.lan.PaymentDone)){

      this.isLoading = true
      axios
        .post('creisFeeManagerUpdate',{cid:cid} )
        .then(() => {
          
          this.isLoading = false
          this.search();
        })
        .catch(error => {
         
          this.isLoading = false
          console.log(error)
        });
      }
    },
    closepopUser:function(){
      this.isPopupUser =0;
    },
  
    search: function() {
      this.isLoading = true
      localStorage.setItem(this.viewParams.name + '_limit', this.listStrage.limit)
      sessionStorage.setItem(this.viewParams.name + '_query', this.listStrage.query)
      sessionStorage.setItem(this.viewParams.name + '_paged', 1)
      axios
        .get(this.viewParams.apiUrl, {
          params: {
            offset: 0, //開始位置
            page:0,
            limit: this.listStrage.limit, //表示件数
         
            q: this.listStrage.query //キーワード
          }
        })
        .then((response) => {
            this.jsonErr = false;
            this.jsonObj = response.data;
            this.tableHeaders = response.data.data.length>0 ? Object.keys(response.data.data[0]):[];
            this.listData = this.jsonObj.data;
            for (var d in this.listData) {
              this.listData[d].ckd = 0;
              this.listData[d].is_change = false;
              this.dateArr[d] = this.listData[d].dueToPaymentDay;
            }
            this.maxPage = Math.ceil(this.jsonObj.total_count / this.listStrage.limit);
            this.listStrage.paged = 1;
            this.isLoading = false
          
      })
        .catch(error => {
            console.log(error),
            this.jsonErr = true,
            this.isLoading = false
          }
        );
    },
    pagenateNum: function(num) {
      this.isLoading = true
      axios
        .get(this.viewParams.apiUrl, {
          params: {
            offset: (num - 1) * this.listStrage.limit, //開始位置
            page:num - 1,
            limit: this.listStrage.limit, //表示件数
            listtype:this.listtype,
            service:this.service,
            utype:this.utype,
            status:this.status,
            step:this.step,
            q: this.listStrage.query //キーワード
          }
        })
        .then(response => (
            this.jsonObj = response.data,
            this.tableHeaders = response.data.data.length>0 ? Object.keys(response.data.data[0]):[],
            this.listData = this.jsonObj.data,
            this.maxPage = Math.ceil(this.jsonObj.total_count / this.listStrage.limit),
            this.listStrage.paged = this.jsonObj.cur_page,
            sessionStorage.setItem(this.viewParams.name + '_paged', this.listStrage.paged),
            this.isLoading = false
          )
        )
        .catch(error => {
            console.log(error),
            this.jsonErr = true,
            this.isLoading = false
          }
        );
    },
    pagenateKey: function(key) {
      switch(key) {
        case 'next':
          if( this.listStrage.paged < this.maxPage ) {
            this.isLoading = true
            this.offset = this.listStrage.limit * this.listStrage.paged
          }
          break
        case 'prev':
          if( this.listStrage.paged > 1 ) {
            this.isLoading = true
            this.offset = this.listStrage.limit * (this.listStrage.paged - 2)
          }
          break
        case 'first':
          if( this.listStrage.paged > 1 ) {
            this.isLoading = true
            this.offset = 0
          }
          break
        case 'last':
          if( this.listStrage.paged < this.maxPage ) {
            this.isLoading = true
            this.offset = this.listStrage.limit * (this.maxPage - 1)
          }
          break
        default:
          this.isLoading = false
          console.log('Non-existent key')
      }
      if(this.isLoading) {
        axios
          .get(this.viewParams.apiUrl, {
            params: {
              offset: this.offset, //開始位置
              page:this.listStrage.paged-1,
              limit: this.listStrage.limit, //表示件数
            
              q: this.listStrage.query //キーワード
            }
          })
          .then(response => {
              this.jsonObj = response.data;
              this.tableHeaders = response.data.data.length>0 ? Object.keys(response.data.data[0]):[];
              this.listData = this.jsonObj.data;
              for(var d in this.listData ){
                this.listData[d].ckd=0;
              }
              this.maxPage = Math.ceil(this.jsonObj.total_count / this.listStrage.limit);
              this.listStrage.paged = this.jsonObj.cur_page;
              sessionStorage.setItem(this.viewParams.name + '_paged', this.listStrage.paged);
              this.isLoading = false;
          }
          )
          .catch(error => {
            console.log(error),
            this.jsonErr = true,
            this.isLoading = false
            }
          );
      }
    },
    pageHide: function(num) {
      if( num < this.listStrage.paged - 4 || num > this.listStrage.paged + 4 ) {
        return true
      }
    },
    listDownload: function() {
      let xmldata
      axios
        .post(this.viewParams.apiUrl + 'download', 
        {
          data: this.listData,
          items: this.checkKeys
        },
        {
          responseType: 'blob'
        }
        )
        .then(response => {
            xmldata = response.data
            this.$nextTick(() => {
              let blob = new Blob([xmldata])
              let link = document.createElement('a')
              link.href = window.URL.createObjectURL(blob)
              link.download = 'corps.xlsx'
              link.click()
            })
            this.downloadAlert = ''
            this.isLoading = false
          }
        )
        .catch(error => {
            this.downloadAlert = 'ダウンロードに失敗しました。'
            console.log(error)
            this.isLoading = false
          }
        );
      
    },
    clearn: function(){

      this.listtype='';
      this.service='';
      this.utype='';
      this.group=0;
      this.step='';
      this.status='';
      this.listStrage.query='';

    }
  },
  watch: {
    checkKeys(newCheckKeys) {
      localStorage.setItem(this.viewParams.name + '_checked', JSON.stringify(newCheckKeys));
    },
  $route(){
    this.cur_lan=localStorage.getItem('cur_lan') !="en" ? "jp":"en";
    let creis_language_data=JSON.parse(localStorage.getItem('creis_language_data'));
    this.lan=creis_language_data['menu_data_'+this.cur_lan]
  },
    isLoading: function() {
      if(!this.isLoading) {
        this.$nextTick(() => {
          window.parent.postMessage(['h'],window.location.host)
        })
      } else {
        //ローディング開始のタイミングでisFocusをリセット
        this.isFocus = -1
      }
    },
  }
}
</script>

<style lang="scss" scoped>
.popup-modal {
  display: block;
      background: rgba(0,0,0,0.8);
}

.modal-body{max-height: 740px;overflow: auto;}
.btn-bottom{position:absolute; bottom: 10px;right:10px}
.form-inline {
  flex-wrap: nowrap;
}

.toolbar-download {
  margin-left: auto;
  margin-right: 1.5rem;
}

.toolbar-display-count {
  align-items: center;
  display: flex;
  flex-wrap: nowrap;
}

@media screen and (max-width: 576px) {

  .toolbar-search {
    flex: 1;
  }

  .toolbar-download {
    justify-content: flex-end;
    margin-bottom: .75rem;
    margin-right: 0;
    order: -1;
    width: 100%;
  }

  .toolbar-display {
    justify-content: flex-end;
    margin-top: .5em;
    width: 100%;
  }

}

.form-search {
  position: relative;
}

.form-search::before {
  content: "\f002";
  font-family: 'Font Awesome 5 Free';
  font-weight: 900;
  height: 1em;
  width: 1em;
}

.ddmenu-modal {
  position: relative;
  z-index: 128;
}

.ddmenu {
  background: #fff;
  border-radius: .15rem;
  left: 50% !important;
  padding: 2.5rem 2.5rem 1.5rem;
  position: fixed;
  top: 2.5rem !important;
  transform: translateX(-50%) !important;
  width: 80vw;
  z-index: 16;
}

.ddmenu .btn-dd-close {
  position: absolute !important;
  right: -.5rem;
  top: -.5rem;
}

.dropdown-menu.show {
  align-content: flex-start;
  animation: none !important;
  display: flex;
  flex-wrap: wrap;
  left: auto !important;
  margin-bottom: 1rem;
  max-height: 40rem;
  overflow-y: scroll !important;
  position: static !important;
  top: auto !important;
  width: 100%;
}

.dropdown-menu.show li {
  flex: 0 1 32.5%;
  word-break: break-all;
}

.ddmenu-bg {
  background: rgba(0, 0, 0, .25);
  height: 100vh;
  left: 0;
  top: 0;
  position: fixed;
  width: 100vw;
  z-index: 8;
}

.fade-leave-active {
  transition: .25s;
  opacity: 0;
}

.fade-enter-active {
  transition: .25s;
  opacity: 0;
}

.fade-enter-to {
  opacity: 1;
}

@media screen and (max-width: 768px) {

  .dropdown-menu.show li {
    flex-basis: 50%;
  }

}

@media screen and (max-width: 500px) {

  .dropdown-menu.show li {
    flex-basis: 100%;
  }

}

.table-responsive {
  overflow: auto !important;
}

table.focus-on tbody tr:hover, table.focus-on tfoot tr:hover {
  cursor: unset;
}

.table thead tr:last-child th {
  background: #fff;
  box-shadow: 0 -.1rem #dee2e6 inset;
  border-bottom: none;
    color:#003f2d;
  position: sticky;
  top: -1px;
  z-index: 8;
}
.table.table-striped td {
  min-width: 60px;
  white-space: normal !important;
  word-wrap: break-word;
}

.table.table-striped .table-focus-on-td {
  align-items: center;
  min-width: auto;
  padding: .35rem !important;
  position: sticky;
  right: 0;
}

.table-focus-on-td .btn {
  white-space: nowrap;
  transition: .15s;
}


.slide-btn-leave-to.btn {
  opacity: 0;
}

.slide-btn-enter-from.btn {
  transform: translateX(-1rem);
  opacity: 0;
}

.slide-btn-enter-to.btn {
  transform: translateX(0);
}

.footable-page.hide {
  display: none;
}
 .table-data-container div, .table-data-container{
  white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    max-width: 200px;
    width: auto;
}


.table-rep-plugin {
  flex: 1;
}

@media screen and (max-width: 768px) {

  .responsive-table-plugin {
    flex-direction: column;
     height: 90vh;
    width: 100%;
  }

  .table-responsive {
    height: 100%;
    max-height: none;
  }

}

.responsive-table-plugin tbody th {
  font-weight: bold;
  min-width: 12.5em;
  white-space: normal !important;
  width: 25%;
  word-break: break-all;
}
.responsive-table-plugin tbody{
  height: 555px;
}
.responsive-table-plugin tbody td {
  white-space: normal !important;
  word-break: break-word;
}

.invalid-feedback {
  display: block;
}
.lline{  float: left;
    word-break: break-all;
    min-height: 50px;
    line-height: 40px;}
.lline div{float: left;width: 62%;}
.lline .list-left{width:38%;float: left;}

.modal-dialog{max-width: 1400px;}
.title,h4{color:#003f2d;}
.pr-0 .btn,.sendmail{margin-left:15px}
.list-info{max-height: 550px; overflow-y:auto;}
.text-body{min-height:350px !important;}
.btn-primary{margin-right: 0px;}
.table-striped thead{display: block;}
.table-striped th,.table-striped td{width: 160px !important; min-width: 160px !important;max-width: 160px !important; overflow: hidden;}
.table-striped th.action,
.table-striped td.action {
  width: 200px !important;
  min-width: 200px !important;
  max-width: 200px !important;
  overflow: hidden;
}
.table-striped th.max,.table-striped td.max{width: 300px !important; max-width: 300px !important;min-width: 300px !important; overflow: visible; position: relative;}
.table-striped tbody{display: block;overflow-y: auto;overflow-x: hidden; width: 100%;}
.table-striped td.max span{overflow: visible;}
.table-striped td.max .remark-box{width: 600px;position: absolute;top: 50px;left:0;z-index: 999;}
.iserror td{background: #ff0000;}
.tinput{width: 100px;padding: 3px 5px;border: #ccc solid 1px;margin-right:10px}
.tarea {
display: block;
  width: 600px;float: left;
  padding: 3px 5px;
  height: 60px;
  border: #ddd solid 1px;
  background: #F3F7F9;
  border-radius: 5px;
}
.remark-box button{margin-top: 10px;}
.table-data-container{
  max-width: 100%;
}
.table.table-striped td{
  height: 120px;
}
.btn-upd{background: #5d8083; color:#fff}
.btn-disable{background: #ccc;}
.mt10{margin: 10px 0 !important;}
.refuse_doing{background:#ddd;margin: 12px 12px 0 !important;}
</style>